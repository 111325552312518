<template>
    <div class="page-admin-organizations table-page__thead--sticky">
        <page-header>
            <template slot="tools">
                <router-link
                    :to="{ name: 'admin.organizations.create' }"
                    class="inline-block btn-primary"
                >
                    Add organization
                </router-link>
            </template>
        </page-header>


        <div class="border-radius overflow-hidden mb-6"> 
            <v-table
                class="admin-table"
                :id="'organizations'"
                :columns="columns"
                row-key="uuid"
                ref="organizations"
                :endpoint="$apiUrl.organizations.base"
                sort-key="created_at"
                :filter-params="filterParams"
                query-params
                no-cache
                :loading="loadingOrganizations"
                @row-click="rowClicked"
            >
                <template slot="cell" slot-scope="{row, col}">
                    <span v-if="col.key == 'sites_count'" class="dotted-underline sites">
                        <v-popover :placement="'top'" trigger="hover" offset="0" popoverClass="sites-popover">
                            <span :class="{ 'dotted-underline': row.sites && row.sites.length }" title="">{{ row.sites_count }} sites</span>

                            <template slot="popover">
                                <span v-if="row.sites && row.sites.length" class="z-50 flex flex-col p-4 pr-8  bg-white border sites-card min-w-48">
                                    <span v-for="(site, index) in row.sites" :key="index + 'site'" class="z-50 block h-32 h-auto py-1 overflow-visible">
                                        {{site.name}}
                                    </span>
                                </span>
                            </template>
                        </v-popover>
                    </span>

                    <span v-else-if="col.key == 'edit'" class="block w-full text-right">
                        <button @click="editOrganization(row.id)">
                            <svg-importer icon-name="icons/edit" :key="`organization_${row.id}_edit`" />
                        </button>
                        <button @click="removeOrganization(row.id)" class="ml-6" href="">
                            <svg-importer width="20" icon-name="icons/close" :key="`organization_${row.id}_close`" />
                        </button>
                    </span>
                    <span v-else>{{ row[col.key] }}</span>
                </template>
            </v-table>
        </div>


        <delete-modal
            :show="showDeleteModal"
            @hide="closeDeleteModal"
            name="Organization"
            :selectedObject="selectedOrganization"
            @delete="initDelete"
            :organizations="organizations"
        >
        </delete-modal>
    </div>
</template>

<script>

    import axios from 'axios'
    import { VPopover } from 'v-tooltip'

    export default {

        name: 'OrganizationIndex',

        components: {
            VPopover
        },

        metaInfo () {
            return { title: 'Organizations' }
        },

        data: () => ({
            filterParams: {
                include:'sites'
            },
            organizations: null,
            newOrganization: null,
            loadingOrganizations: false,
            showDeleteModal: false,
            selectedOrganization:  null,
            showMoveSites: false,
            deleteAction: '',
            columns: [
                {
                    title: "ID",
                    key: "id",
                    toggled: true,
                    sortable: true,
                    width: "w-24"
                },
                {
                    title: "Name",
                    key: "name",
                    toggled: true,
                    sortable: true,
                    width: "w-48"
                },
                {
                    title: "Key",
                    key: "key",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "Sites",
                    key: "sites_count",
                    toggled: true,
                    sortable: true,
                    width: "w-1/2"
                },
                {
                    title: "",
                    key: "edit",
                    toggled: true,
                    sortable: false,
                    width: "w-1/3"
                },
            ]
        }),

        created() {
            this.fetchOrganizations();
        },

        computed: {
            routeIncludesAdmin() {
                return this.$route.path.includes('admin') ?  true : false;
            },
        },

        methods: {
            editOrganization(id) {
                this.$router.push({
                    name: "admin.organizations.edit",
                    params: {
                        id: id
                    }
                });
            },
            removeOrganization(id) {
                this.showDeleteModal = true;
                this.selectedOrganization = this.organizations.find(organization => organization.id == id);
            },
            async fetchOrganizations() {
                const { data } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000&include=sites,users');

                this.organizations = data.data;
            },
            initDelete(deleteAction) {
                if(deleteAction.command == 'move') {
                    this.moveSites(deleteAction);
                } else if(deleteAction.command == 'delete') {
                    this.deleteOrganization();
                }
            },
            async moveSites(deleteAction) {
                let params = {
                    newOrganization: deleteAction.newOrganization.id,
                }

                try {
                    const { data } = await axios.post(`${this.$apiUrl.organizations.base}/` + this.selectedOrganization.id + "/move-sites/", params);
                    this.$refs.organizations.table.fetchRows();
                    this.closeDeleteModal();
                }
                catch (e) {
                    console.log(e, 'error');
                }
            },
            closeDeleteModal() {
                this.showDeleteModal = false;
                //this.deleteAction = '';
                this.selectedOrganization = null;
                //this.newOrganization = null;
                this.showMoveSites = false;
            },
            async deleteOrganization() {
                try {
                    const { data } = await axios.delete(`${this.$apiUrl.organizations.base}/` + this.selectedOrganization.id);
                    this.$refs.organizations.table.fetchRows();
                    this.closeDeleteModal();
                }
                catch (e) {
                    console.log(e, 'error');
                }
            },
            rowClicked(row) {
                /*this.$router.push({
                    name: "admin.organizations.edit",
                    params: {
                        id: row.id
                    }
                }); */
            },
        }
    }
</script>

<style lang="scss">
.page-admin-organizations {
    .table-wrapper {
        max-height: calc(100vh - 230px);
        overflow: auto;
    }
}
</style>
